import * as React from "react";
import { useInView } from "react-intersection-observer";
import { Flex, Box, Text, Container } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import QuoteIcon from "../assets/quote.svg";
import { useSpring, animated, config } from "react-spring";

const Testimonial = ({ image, quote }) => {
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  const { transform } = useSpring({
    transform: inView ? `scale(1) rotate(0deg)` : `scale(0) rotate(180deg)`,
    config: config.wobbly,
  });
  return (
    <Flex
      ref={ref}
      sx={{
        flexDirection: "column",
        alignItems: ["center", "flex-start"],
        width: ["100%", "50%"],
        maxWidth: 400,
        my: 9,
        px: 8,
      }}
    >
      <Box
        sx={{
          width: [200, 200, 250],
          ".testimonial__img": {
            borderRadius: "50%",
            width: [200, 200, 250],
            height: [200, 200, 250],
          },
        }}
      >
        <animated.div style={{ transform }}>{image}</animated.div>
      </Box>
      <Box
        sx={{
          mt: 6,
        }}
      >
        <QuoteIcon />
      </Box>

      <Text
        as="p"
        sx={{
          mt: 4,
        }}
      >
        {quote}
      </Text>
    </Flex>
  );
};

const Musk = (props) => (
  <Testimonial
    {...props}
    quote={`We keep HODLing onto dogefood, until we eetz it.`}
    image={
      <StaticImage
        src="../images/testimonial-1.png"
        placeholder="none"
        alt="Elon Musk"
        imgClassName="testimonial__img"
      />
    }
  ></Testimonial>
);
const Cuban = (props) => (
  <Testimonial
    {...props}
    quote={`Come join the DOGE side.`}
    image={
      <StaticImage
        src="../images/testimonial-2.png"
        placeholder="none"
        alt="Mark Cuban"
        imgClassName="testimonial__img"
      />
    }
  ></Testimonial>
);

const Testimonials = (props) => {
  return (
    <Box bg="yellow" id="testimonials-section">
      <Container py={[12, 13]}>
        <Flex
          px={9}
          sx={{
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Musk />
          <Cuban />
        </Flex>
      </Container>
    </Box>
  );
};

export default Testimonials;
