import * as React from "react";
import { Box, Flex, Heading, Button, Text, Container } from "theme-ui";

const ManifestoSection = (props) => {
  return (
    <Box bg="white">
      <Container px={9} py={[13, 14]}>
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: ["100%", "50%"],
              maxWidth: "550px",
              pr: [0, 10],
              mb: [10, 0],
            }}
          >
            <Heading
              as="h1"
              variant="h1"
              sx={{
                fontFamily: "body",
              }}
            >
              The original doge manifesto
            </Heading>

            <Text as="p">
              “In the words of the creator of doge: Joy, kindness, learning,
              giving, empathy, fun, community, inspiration, creativity,
              generosity, silliness, absurdity. These types of things are what
              makes Dogecoin worthwhile to me. If the community embodies these
              things, that's True Value.”
            </Text>
          </Box>
          <Flex
            sx={{
              width: ["100%", "50%"],
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ maxWidth: 350 }}>
              <Button
                as="a"
                href="https://secure.aspca.org/donate/donate"
                target="_blank"
                sx={{ mb: 8 }}
              >
                Donate to the ASPCA
              </Button>
              <Button
                as="a"
                href="https://4pawsforability.org/donate-to-4-paws"
                target="_blank"
              >
                Donate Service Dogs for Kids
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default ManifestoSection;
