import React from "react";
import { Global, css } from "@emotion/react";
import Header from "./Header";
import Footer from "./Footer";
import Seo from "./SEO";

const fontCSS = `
@font-face {
  font-family: "Platform";
  src: url("/fonts/Platform-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Platform";
  src: url("/fonts/Platform-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "ComicSans";
  src: url('/fonts/ComicSans-Regular.woff') format('woff'),
    url("/fonts/ComicSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
// @font-face {
//   font-family: "ComicSans";
//   src: url("/fonts/ComicSans-RegularItalic.ttf") format("truetype");
//   font-weight: 400;
//   font-style: italic;
// }
@font-face {
  font-family: "ComicSans";
  src: url("/fonts/ComicSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
// @font-face {
//   font-family: "ComicSans";
//   src: url("/fonts/ComicSans-BoldItalic.ttf") format("truetype");
//   font-weight: 700;
//   font-style: italic;
// }
`;

const Layout = (props) => (
  <>
    <Global
      styles={css`
        ${fontCSS}
      `}
    />
    <Seo />
    <Header />
    <main style={{ overflow: "hidden" }}>{props.children}</main>
    <Footer />
  </>
);

export default Layout;
