import * as React from "react";
import { useState, useEffect } from "react";
import { keyframes } from "@emotion/react";
import { Box } from "theme-ui";
import { useInView } from "react-intersection-observer";

const ticker = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: linear;
  }
  99.99% {
    transform: translate3d(-1440px, 0, 0);
    animation-timing-function: step-start;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const imgUrl = "/dogesea.jpg";
const DogeSea = (props) => {
  const { inView, ref } = useInView({
    threshold: 0,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (inView && !image) {
      setImage(imgUrl);

      const img = new Image();
      img.onload = () => setIsLoaded(true);
      img.src = imgUrl;
    }
  }, [inView, image]);

  const styles = {};
  if (image && inView) {
    styles.animation = `${ticker} 20s linear infinite`;
  }
  if (image) {
    styles.backgroundImage = `url(${image})`;
  }

  return (
    <Box
      ref={ref}
      sx={{
        overflow: "hidden",
        transition: "opacity 200ms ease-in",
        opacity: isLoaded ? 1 : 0,
      }}
    >
      <Box
        sx={{
          ...styles,
          backgroundRepeat: "repeat-x",
          backgroundSize: "contain",
          width: "4320px",
          height: "331px",
        }}
      />
    </Box>
  );
};

export default DogeSea;
