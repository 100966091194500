import * as React from "react";
import { Button } from "theme-ui";

const BuyButton = (props) => (
  <form action="https://www.coinpayments.net/index.php" method="post">
    <input type="hidden" name="cmd" value="_pay" />
    <input type="hidden" name="reset" value="1" />
    <input
      type="hidden"
      name="merchant"
      value="b4b6c044cd46466026283d6731053e19"
    />
    <input
      type="hidden"
      name="item_name"
      value="Sundays Food for Doge, Limited Edition Mini"
    />
    <input type="hidden" name="item_number" value="1" />
    <input type="hidden" name="currency" value="DOGE" />
    <input type="hidden" name="amountf" value="10.50000000" />
    <input type="hidden" name="quantity" value="1" />
    <input type="hidden" name="allow_quantity" value="0" />
    <input type="hidden" name="want_shipping" value="1" />
    <input
      type="hidden"
      name="success_url"
      value="https://sundaysfordogs.com/doge-thanks"
    />
    <input
      type="hidden"
      name="cancel_url"
      value="https://sundaysfordogs.com/"
    />
    <input type="hidden" name="allow_extra" value="1" />
    {/* <input
      type="image"
      src="https://www.coinpayments.net/images/pub/buynow-grey.png"
      alt="Buy Now with CoinPayments.net"
    /> */}
    <Button type="submit" sx={{ width: "100%" }}>
      Must Buy
    </Button>
  </form>
);

export default BuyButton;
