import * as React from "react";
import { Box, Flex, Heading, Button, Text, Container, Link } from "theme-ui";
import { keyframes } from "@emotion/react";
import DogeCoin from "../assets/dogecoin.svg";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import BuyButton from "./BuyButton";

const ProductPhoto = (props) => {
  const {
    productImage: { childImageSharp: nodes },
  } = useStaticQuery(graphql`
    query {
      productImage: file(relativePath: { eq: "product-box.png" }) {
        name
        sourceInstanceName
        childImageSharp {
          mobile: gatsbyImageData(
            layout: CONSTRAINED
            height: 576
            width: 640
            placeholder: NONE
            quality: 75
            transformOptions: { fit: COVER, cropFocus: CENTER }
          )
          desktop: gatsbyImageData(
            placeholder: NONE
            quality: 75
            layout: CONSTRAINED
          )
        }
      }
    }
  `);
  const images = withArtDirection(getImage(nodes.desktop), [
    {
      media: `(max-width: 639px)`,
      image: getImage(nodes.mobile),
    },
  ]);
  return (
    <Box
      sx={{
        ".product-photo": {
          height: "auto",
        },
        "@media (max-width: 639px)": {
          ".product-photo": {
            height: "90vw",
          },
        },
      }}
    >
      <GatsbyImage
        className="product-photo"
        alt="A box of Sundays Doge food"
        image={images}
      />
    </Box>
  );
};

const neon = (color, alternate) => keyframes`
  ${alternate ? "from" : "to"} {
    text-shadow: 0 0 2px ${color}, 0 0 0px ${color}, 0 0 0px ${color};
  }
  ${alternate ? "to" : "from"} {
    text-shadow: 0 0 6px ${color}, 0 0 25px ${color}, 0 0 40px ${color};
  }
`;

const CornerText = ({ alternate, text, rotate, top, bottom, left, right }) => {
  const pos = {
    top,
    bottom,
    left,
    right,
  };
  return (
    <Box
      sx={{
        position: "absolute",
        transform: `rotate(${rotate}deg)`,
        transformOrigin: "center center",
        zIndex: 1,
        ...pos,
      }}
    >
      <Text
        sx={{
          color: "yellow",
          fontWeight: "bold",
          fontSize: ["24px", "28px", "32px", "38px"],
          WebkitTextStroke: "2px #FF07D7",
          animation: `${neon(
            "#FF07D7",
            alternate
          )} 1s ease-in-out infinite alternate`,
        }}
      >
        {text}
      </Text>
    </Box>
  );
};

const Hero = (props) => (
  <Box bg="blue" sx={{ overflow: "hidden" }}>
    <Container>
      <Flex
        sx={{
          flexDirection: ["column", "row-reverse"],
          alignItems: "center",
          justifyContent: "center",
          py: [10],
        }}
      >
        <Box
          sx={{
            width: ["100%", "60%"],
            flex: `1 1 auto`,
            position: "relative",
            mt: ["20%", 100, 150, 200],
            mb: ["15%", 100, 150],
          }}
        >
          <CornerText
            alternate
            rotate={-25}
            text="very taste"
            top={["-12%", "-15%", "-15%", "-12%"]}
            left={["3%", "6%", "6%", "8%", "10%"]}
          />
          <CornerText
            rotate={25}
            text="such nutrition"
            top={["-13%", "-15%", "-15%", "-14%", "-12%"]}
            right={["3%", "1%", "3%", "4%", "5%"]}
          />
          <CornerText
            rotate={25}
            text="Good boy"
            bottom={["-5%", "-6%", "-8%", "-7%"]}
            left={["3%", "8%", "10%", "12%"]}
          />
          <CornerText
            alternate
            rotate={-25}
            text="WOW"
            bottom={["-5%", "-10%", "-10%", "-10%"]}
            right={["5%", "12%", "14%", "16%", "16%"]}
          />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <ProductPhoto />
          </Box>
        </Box>
        <Box
          sx={{
            color: "white",
            p: [10, 9, 9, 10],
            pr: [10, 0],
            width: ["100%", "40%"],
            flex: `1 1 auto`,
            maxWidth: 450,
          }}
        >
          <Flex
            sx={{
              maxWidth: 250,
              mx: -2,
              mb: "10px",
            }}
          >
            {[0, 1, 2].map((i) => (
              <Box
                key={i}
                sx={{
                  px: 2,
                  width: "33%",
                  height: "33%",
                  transform: `rotate(20deg)`,
                }}
              >
                <DogeCoin width="100%" height="100%" />
              </Box>
            ))}
          </Flex>
          <Heading
            as="h2"
            variant="h2"
            color="white"
            sx={{
              mb: "10px",
              fontSize: [5, 5, 5, 6, 7],
            }}
          >
            Food for Doge
          </Heading>
          <Heading
            as="h3"
            variant="h3"
            mb={4}
            sx={{
              fontSize: [3, 4, 4, 5],
              opacity: 0.5,
              fontWeight: 400,
            }}
          >
            10.5 Dogecoin
          </Heading>
          <Text color="white" as="p" mb={8}>
            420 limited-edition cereal box bundles of real air-dried doge food,
            only available for purchase via Dogecoin, sent to your door and as a
            VFT (very fungible treat). Each order comes with three mini cereal
            boxes.{" "}
            <Link
              href="https://help.sundaysfordogs.com/en/articles/5228157-food-z-for-doge"
              target="_blank"
              sx={{
                textDecoration: "none",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: "primary",
              }}
            >
              Questions?
            </Link>
          </Text>
          <BuyButton />
        </Box>
      </Flex>
    </Container>
  </Box>
);

export default Hero;
