import * as React from "react";
import { Link, Box, Flex, Text } from "theme-ui";
import { keyframes } from "@emotion/react";
import Logo from "../assets/sundays-logo.svg";
import { InView } from "react-intersection-observer";

const ticker = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: linear;
  }
  99.99% {
    transform: translate3d(-608px, 0, 0);
    animation-timing-function: step-start;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;
const TickerItem = (props) => (
  <Text
    sx={{
      color: "white",
      fontFamily: "heading",
      ml: "12px",
      fontSize: ["15px", "15px", "15px", "15px"],
      width: "140px",
      letterSpacing: "0.02em",
      textAlign: "center",
      flexShrink: 0,
    }}
    as="div"
  >
    {tickerText}
  </Text>
);

const tickerText = `10.5 doge = 3 boxes`;
const Ticker = (props) => {
  return (
    <Box sx={{ overflow: "hidden", bg: "black" }}>
      <InView>
        {({ inView, ref }) => (
          <Flex
            ref={ref}
            sx={{
              p: 3,
              alignItems: "center",
              animation: inView ? `${ticker} 10s linear infinite` : "none",
            }}
          >
            {[...new Array(20)].map((_, i) => (
              <TickerItem key={i} />
            ))}
          </Flex>
        )}
      </InView>
    </Box>
  );
};

const Header = (props) => {
  return (
    <Box
      as="header"
      sx={{
        position: "relative",
        zIndex: 10,
        bg: "white",
      }}
    >
      <Ticker />
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "57px",
        }}
      >
        <Link
          href="https://sundaysfordogs.com/"
          title="Sundays Food for Dogs"
          sx={{ lineHeight: 0, height: "21px" }}
        >
          <Logo />
        </Link>
      </Flex>
    </Box>
  );
};

export default Header;
