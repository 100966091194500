import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = (props) => {
  const pathname = "/";
  const { site } = useStaticQuery(query);
  const { title, description, url, image, twitterUsername } = site.siteMetadata;
  const seo = {
    title,
    description,
    image: `${url}${image}`,
    url: `${url}${pathname}`,
  };
  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        href="/favicons/apple-touch-icon-120x120.png"
      />
      <link rel="shortcut icon" type="image/png" href="/favicons/favicon.png" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="/favicons/favicon.ico"
      />
    </Helmet>
  );
};
export default SEO;
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
};
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        image
        twitterUsername
        url
      }
    }
  }
`;
