import * as React from "react";
import { Box, Flex, Heading, Text, Container } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

const DogePhoto = ({ isVisible, delay }) => (
  <Box
    sx={{
      flexShrink: 0,
      width: ["33%", 243],
      borderRadius: "4px",
      overflow: "hidden",
      transition: `all 250ms ease-in`,
      transitionDelay: `${delay}ms`,
      opacity: isVisible ? 1 : 0,
      transform: `translate3d(${isVisible ? "0px" : "-25px"}, 0px, 0px)`,
      px: 2,
    }}
  >
    <StaticImage
      placeholder="none"
      src="../images/beach-bum.jpg"
      alt="Dog at the beach"
    />
  </Box>
);

const WhatIsItSection = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  return (
    <>
      <Box bg="yellow" sx={{ overflow: "hidden" }} {...props}>
        <Container px={9} py={13}>
          <Flex
            sx={{
              flexDirection: ["column", "row"],
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: ["100%", "50%"],
                pr: [0, 8],
                mb: [8, 0],
              }}
            >
              <Heading as="h1" variant="h1">
                What is Doge Food?
              </Heading>

              <Text as="p">
                Doge food is dog food, made by Sundays for dogs. We make a
                healthy, human-grade, quality dog food. We thought we’d have
                some fun and spread our love of dogs, doge, and food.
              </Text>
            </Box>
            <Flex sx={{ width: ["100%", "50%"] }} ref={ref}>
              {[0, 1, 2].map((i) => (
                <DogePhoto isVisible={inView} delay={i * 250} key={i} />
              ))}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default WhatIsItSection;
