import * as React from "react";
import { Box, Flex, Text, Container } from "theme-ui";
import NaturalIcon from "../assets/natural.svg";
import AirDriedIcon from "../assets/airdried.svg";
import TasteIcon from "../assets/taste.svg";
import WowIcon from "../assets/wow.svg";

const ValueProp = ({ width, text, Icon }) => (
  <Flex
    sx={{
      width: ["50%", "25%"],
      p: 8,
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Box
      sx={{
        width: [0.75 * width, 0.75 * width, width],
      }}
    >
      <Icon width="100%" height="auto" />
    </Box>
    <Box sx={{ mt: 6, textAlign: "center" }}>
      <Text
        sx={{
          fontFamily: "platform",
          fontSize: ["18px", "20px", "24px"],
          lineHeight: 1.2,
          letterSpacing: ".02em",
        }}
      >
        {text}
      </Text>
    </Box>
  </Flex>
);

const ValueProps = (props) => (
  <Box
    bg="white"
    sx={{
      py: "75px",
    }}
  >
    <Container>
      <Flex
        sx={{
          flexDirection: "row",
          flexWrap: ["wrap", "nowrap"],
          justifyContent: "space-around",
          alignItems: ["flex-end"],
        }}
      >
        <ValueProp Icon={NaturalIcon} text="very natural" width={77} />
        <ValueProp Icon={TasteIcon} text="such taste" width={75} />
        <ValueProp Icon={AirDriedIcon} text="air dried" width={130} />
        <ValueProp Icon={WowIcon} text="wow" width={100} />
      </Flex>
    </Container>
  </Box>
);

export default ValueProps;
