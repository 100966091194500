import * as React from "react";
import { Link, Flex } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";

const Footer = (props) => {
  return (
    <Flex
      as="footer"
      sx={{
        bg: "yellow",
        pt: 14,
        pb: 12,
        px: 9,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Link href="https://sundaysfordogs.com/">
        <StaticImage
          style={{
            maxWidth: "368px",
          }}
          placeholder="none"
          src="../images/made-by-sundays.png"
          alt="Made by Sundays"
        />
      </Link>
    </Flex>
  );
};

export default Footer;
