import * as React from "react";
import { Box, Flex } from "theme-ui";

//<svg width="310" height="30" xmlns="http://www.w3.org/2000/svg">
//<path d="M 0 15 L 300 15" stroke="#000"></path>
//</svg>

const BorderIcon = ({ Icon, right }) => {
  return (
    <Box
      sx={{
        zIndex: 10,
        position: "relative",
        pointerEvents: "none",
        width: "100%",
      }}
    >
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          left: "10%",
          width: "80%",
          justifyContent: right ? "flex-end" : "flex-start",
        }}
      >
        <Box
          sx={{
            transform: "translateY(-50%)",
          }}
        >
          <Icon />
        </Box>
      </Flex>
    </Box>
  );
};

export default BorderIcon;
