import * as React from "react";
import { Box, Text, Flex, Heading, Link } from "theme-ui";
import { useInView } from "react-intersection-observer";
const Scene = React.lazy(() => import("./Scene"));

const FeedTheDoge = (props) => {
  const isSSR = typeof window === "undefined";
  const { inView, ref } = useInView({ threshold: 0, triggerOnce: true });

  if (isSSR) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={{
        bg: "yellow",
        pt: 12,
      }}
    >
      <Flex
        sx={{
          width: "100%",
          // maxWidth: "800px",
          // mx: "auto",
          height: "800px",
          maxHeight: "95vh",
          minHeight: "560px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box p={8} maxWidth={500}>
          <Heading
            as="h1"
            variant="h1"
            sx={{ textAlign: "center", fontFamily: "body" }}
          >
            Get this doge swole with Sundays
          </Heading>
          <Text as="p" sx={{ textAlign: "center" }}>
            Tap the box to see what happens
          </Text>
        </Box>
        <Box
          sx={{
            bg: "blue",
            flex: `1 1 auto`,
            width: "100%",
            position: "relative",
            pb: 12,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {inView && (
              <React.Suspense fallback={null}>
                <Scene />
              </React.Suspense>
            )}
          </Box>
        </Box>
      </Flex>
      <Box py={1} px={2} sx={{ textAlign: "right" }}>
        <Text
          sx={{
            textAlign: "right",
            fontSize: ["10px", "12px", "12px", "12px", "12px"],
            opacity: 0.5,
          }}
        >
          Credit:{" "}
          <Link
            href="https://skfb.ly/6VAMF"
            target="_blank"
            sx={{ color: "text" }}
          >
            Swole Doge
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default FeedTheDoge;
