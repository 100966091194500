import * as React from "react";
import { useState } from "react";
import { Box, Flex, Heading, Button, Text, Container } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import { Waypoint } from "react-waypoint";
import { useSpring, animated, config } from "react-spring";

const Doge = (props) => (
  <StaticImage
    placeholder="none"
    src="../images/doge-crop.png"
    alt="Doge"
    style={{ height: "100%", maxWidth: "none" }}
  />
);

const ShareSection = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { transform } = useSpring({
    config: {
      ...config.molasses,
      mass: 2,
    },
    transform: isVisible
      ? `translate3d(-10%, 15%, 0)`
      : `translate3d(-10%, 100%, 0)`,
  });

  return (
    <Waypoint
      bottomOffset="20%"
      onEnter={() => setIsVisible(true)}
      onLeave={() => setIsVisible(false)}
    >
      <Box bg="white" sx={{ overflow: "hidden" }}>
        <Container pr={8} pl={[8, 0]} pb={13} pt={[0, 13]} ml={0}>
          <Flex
            sx={{
              flexDirection: ["column", "row"],
              alignItems: "center",
            }}
          >
            <Flex sx={{ width: ["100%", "50%"], maxWidth: ["50vh", 600] }}>
              <Box
                sx={{
                  transform: [`rotate(180deg)`, `rotate(90deg)`],
                }}
              >
                <animated.div
                  style={{
                    transform,
                  }}
                >
                  <Doge />
                </animated.div>
              </Box>
            </Flex>
            <Box
              sx={{
                width: ["100%", "50%"],
                maxWidth: "650px",
                pl: [0, 8],
                mt: [8, 0],
              }}
            >
              <Heading
                as="h1"
                variant="h1"
                sx={{
                  fontFamily: "body",
                }}
              >
                Want a free box of dog(e) food?
              </Heading>

              <Text as="p" mb={8}>
                Tweet a pic of your doge at us, and we’ll send you a free box of
                Sundays.
              </Text>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: ["none", "350px"],
                  textAlign: "center",
                }}
              >
                <Button
                  as="a"
                  href="https://twitter.com/intent/tweet?url=https%3A%2F%2Ffoodfordoge.com%2F&text=my%20doge%20would%20love%20to%20try%20%40sundaysfordogs%21"
                  target="_blank"
                >
                  Tweet my doge
                </Button>
                <Text
                  as="div"
                  sx={{
                    mt: 4,
                    fontSize: [0, 1],
                    opacity: 0.3,
                  }}
                >
                  (bonus gift for shiba inu’s)
                </Text>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Waypoint>
  );
};

export default ShareSection;
