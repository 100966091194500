import * as React from "react";
import Layout from "../components/Layout";
import FeedTheDoge from "../components/FeedTheDoge";
import Testimonials from "../components/Testimonials";
import Hero from "../components/Hero";
import ValueProps from "../components/ValueProps";
import WhatIsItSection from "../components/WhatIsItSection";
import ShareSection from "../components/ShareSection";
import ManifestoSection from "../components/ManifestoSection";
import DogeSea from "../components/DogeSea";
import BorderIcon from "../components/BorderIcon";
import CoinIcon from "../assets/dcoin.svg";
import DogeCoinIcon from "../assets/dogecoin.svg";
import { InView } from "react-intersection-observer";
const LazyRocket = React.lazy(() => import("../components/Rocket"));
// markup
const IndexPage = () => {
  const isSSR = typeof window === "undefined";
  return (
    <Layout>
      <Hero />
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <ValueProps />
            {!isSSR && inView && (
              <React.Suspense fallback={null}>
                <LazyRocket scrollTrigger="#what-section" />
              </React.Suspense>
            )}
            <WhatIsItSection id="what-section" />
          </div>
        )}
      </InView>

      <ShareSection />
      <BorderIcon Icon={CoinIcon} />
      <Testimonials />
      <DogeSea />

      <ManifestoSection />
      <BorderIcon Icon={DogeCoinIcon} right />
      <FeedTheDoge />
    </Layout>
  );
};

export default IndexPage;
